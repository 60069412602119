<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Título *</label>
                <input class="form-control" type="text" v-model="Documento.Titulo" placeholder="Título">
                <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Titulo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
            </div>
        </div>
        <div class="row mb-3" 
        v-show="(PrimerRegistro === 0 || PrimerRegistro.IdDocumento == Documento.IdDocumento) || Documento.Tipo==1">
            <div class="col-lg-12">
                <label for="">Archivo *</label>
                <div class="custom-file-input-image">
                    <input @change="uploadImage()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                    <input type="text" v-model="NombreArchivo" class="form-control">
                    <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <label for="">Descripción</label>
                <textarea class="form-control" cols="30" rows="2" v-model="Documento.Descripcion"></textarea>
            </div>
        </div>
    </div>
</template>
<script>
import Cbtnsave from '@/components/Cbtnsave.vue';
import Cvalidation from '@/components/Cvalidation.vue';

export default{
    name:'Form',
    props:[
        "poBtnSave",
        "IdActivo",
        "NombreTabla",
        "CantidadRegistros",
        "PrimerRegistro"
    ],
    data(){
        return{
            Modal:true,
            FormName:'Documentos',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            Documento:{
                IdDocumento:'',
                IdActivo:'',
                Titulo:'',
                Descripcion:'',
                Tipo:0,
                NombreArchivo:''
            },
            NombreArchivo:'Elegir Archivo (5MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdDocumento',this.Documento.IdDocumento);
            formData.set('IdActivo',this.IdActivo);
            formData.set('Titulo',this.Documento.Titulo);
            formData.set('Descripcion',this.Documento.Descripcion);
            formData.set('Tipo',this.Documento.Tipo);
            formData.set('NombreArchivo',this.Documento.NombreArchivo);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('manuales/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $('#'+this.EmitSeccion).modal('hide');
                if (this.Documento.Tipo==1) {
                    this.bus.$emit('List_'+this.EmitSeccion,1);
                }
                if (this.Documento.Tipo==2) {
                    this.bus.$emit('List_'+this.EmitSeccion,2);
                }
                // this.bus.$emit('Listar');
            }).catch( err => {
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2; 
            });
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Documento={
                IdDocumento:'',
                IdActivo:'',
                Titulo:'',
                Descripcion:'',
                Tipo:0,
                NombreArchivo:'Elegir Archivo (5MB)',
            },
            this.errorvalidacion=[];
            this.NombreArchivo='Elegir Archivo (5MB)';
        },
        Recuperar(){
            this.$http.get('manuales/recovery',{
                params:{
                    IdDocumento:this.Documento.IdDocumento
                }
            }).then((res)=>{
                const objres=res.data.data.documento;
                this.Documento.IdDocumento=objres.IdDocumento;
                this.Documento.IdActivo=objres.IdActivo;
                this.Documento.Titulo=objres.Titulo;
                this.Documento.Descripcion=objres.Descripcion;
                this.Documento.NombreArchivo=objres.NombreArchivo;
            })
        },
        uploadImage(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 5) {
                this.$toast.info('Solo se puede subir archivos menores a 5 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.NombreArchivo='Elegir archivo (5MB)';
                return false;
            }

            this.NombreArchivo=image.name;
        }
    },
    created(){
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id)=>{
            // console.log("a");
            // this.Documento.IdActivo=this.IdActivo
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (this.NombreTabla=="Manuales") {
                this.Documento.Tipo=1;
            }
            if (this.NombreTabla=="Programas") {
                this.Documento.Tipo=2;
            }
            if (Id > 0) {
				this.Documento.IdDocumento = Id;
				this.Recuperar();
			}
        });
    }
}
</script>